import { Component } from 'react';
import { AppProps } from 'single-spa';
import { TAppConf, TResource, TUser } from '@gf-tech/types';
import { http, store } from './utils';
import { RootRouter } from './components/RootRouter';
import styled from 'styled-components';

type TState = {
    router: TResource;
};
const RootStyle = styled.div`
    flex: 1;
    width: 0;
    margin: 24px;
    padding: 24px;
    padding-top: 8px;
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    height: calc(100% - 48px);
    .ant-table-cell {
        .ant-btn-link {
            padding: 0 0;
            height: auto;
            + .ant-btn-link {
                margin-left: 8px;
            }
        }
    }
    .ant-table-tbody
        > tr.ant-table-row-selected
        > td.ant-table-cell-fix-right::before {
        background: var(--ant-primary-1);
    }
`;
export default class Root extends Component<AppProps & TAppConf, TState> {
    constructor(props: AppProps & TAppConf) {
        super(props);

        const appConf = props.appConf;
        store.namespaces(appConf.namespace);
        http.config({ baseURL: appConf.apiHost, store });

        const userInfo: TUser = store.get('userInfo');
        const router = userInfo.resList.find(item => item.uriRoute === '/itm');
        this.state = { router };
    }
    render() {
        return (
            <RootStyle>
                <RootRouter base='/itm' router={this.state.router}></RootRouter>
            </RootStyle>
        );
    }
}
